<template>
  <div class="reservation">
    <van-notice-bar :text="notice" v-if="active === 0 && notice" />
    <van-sticky offset-top="1.227rem">
      <van-steps
        :active="active"
        active-color="#0B6CF9"
        @click-step="clickStep"
      >
        <van-step>选择场次</van-step>
        <van-step>填写信息</van-step>
        <van-step>完成预约</van-step>
      </van-steps>
    </van-sticky>
    <div class="information">
      <p class="tips">
        温馨提示：1、如您完成预约后不能及时参观，须在您选择的<span
          class="red_color"
          >进馆时间开始前{{
            data.retreatTimeHour ? data.retreatTimeHour + '小时' : ''
          }}{{ data.retreatTimeMin }}分钟</span
        >提前取消预约，如<span class="red_color"
          >{{ data.maxDefaultsNum }}次</span
        >不及时取消，账号将在<span class="red_color"
          >{{ data.resetDefaultsDay }}天</span
        >内无法进行预约。
      </p>
      <template v-if="type === '儿童科学乐园门票预约'">
        <p class="tips">
          2、<span class="red_color">最少</span>预约<span class="red_color"
            >2</span
          >人，联系人必须年满<span class="red_color">18周岁以上</span
          >，随行人员必须有<span class="red_color">1人年龄在14周岁以下!</span>
        </p>
        <p class="tips">
          3、请使用<span class="red_color">身份证</span
          >预约儿童科学乐园门票，其他证件暂不支持
        </p>
      </template>
    </div>
    <template v-if="active === 0">
      <div class="box">
        <div class="title title_box">
          <span>参观日期</span
          ><span v-if="dateActive !== null"
            >{{ dateList[dateActive].scheduleDate }}（周{{
              dateList[dateActive].title
            }})</span
          >
        </div>
        <div class="date_box">
          <div
            v-for="(item, index) in dateList"
            :key="index"
            class="date_item"
            @click="item.isClick ? onDate(index) : null"
            :style="{
              backgroundColor: item.isClick
                ? dateActive === index
                  ? '#076cfa'
                  : '#fff'
                : '#fff',
              color: item.isClick
                ? dateActive === index
                  ? '#fff'
                  : '#076cfa'
                : '#999',
              borderColor: item.isClick
                ? dateActive === index
                  ? '#076cfa'
                  : '#076cfa'
                : '#999',
            }"
          >
            <span>{{ item.day }} ({{ item.title }})</span>
            <span>{{
              !item.isClose
                ? !item.server
                  ? '10点开放'
                  : item.isDue
                  ? '已截止'
                  : item.remainingQuantity > 0
                  ? `余${item.remainingQuantity}`
                  : '已约满'
                : type === '儿童科学乐园门票预约'
                ? '闭园'
                : '闭馆日'
            }}</span>
            <img
              v-show="dateActive === index"
              src="@/assets/images/icon5.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title">
          <span>{{
            type === '儿童科学乐园门票预约' ? '进园时间' : '进馆时间'
          }}</span>
        </div>
        <div class="sessions" v-if="dateActive !== null">
          <div
            v-for="(item, index) in scheduleList[dateActive]"
            :key="index"
            @click="
              item.remainingQuantity > 0 && item.status === 1 && item.cannot
                ? selectSession(index)
                : null
            "
            :class="{
              sessionsActive: sessionsActive === index,
              state: !(
                item.remainingQuantity > 0 &&
                item.status === 1 &&
                item.cannot
              ),
            }"
          >
            <span>{{ item.start + ' - ' + item.end }}</span>
            <span>{{
              item.status === 2
                ? '已截止'
                : item.remainingQuantity > 0
                ? item.cannot
                  ? `余${item.remainingQuantity}张`
                  : '不可预约'
                : '已约满'
            }}</span>
            <img
              v-show="sessionsActive === index"
              src="@/assets/images/icon5.png"
              alt=""
            />
          </div>
        </div>
        <div class="sessions" v-else>
          <span class="tips">请先选择日期</span>
        </div>
        <div class="quantity" v-if="sessionsActive !== null">
          <div>
            <p>预约总人数：</p>
            <van-stepper
              v-model="quantity.yy"
              theme="round"
              button-size="18"
              disable-input
              min="0"
              :max="
                steppers.yy && steppers.yy.max ? steppers.yy.max : undefined
              "
              default-value="0"
            />
          </div>
          <div>
            <span>{{ steppers.yy && steppers.yy.title }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="box">
        <div class="title">
          <img src="@/assets/images/icon3.png" alt="" />
          <span>{{
            type === '儿童科学乐园门票预约' ? '儿童乐园展票' : '温州科技馆门票'
          }}</span>
        </div>
        <div class="profile">
          <p>
            进馆时间：<span>{{
              dateList[dateActive].scheduleDate +
              ' ' +
              scheduleList[dateActive][sessionsActive].start +
              ' - ' +
              scheduleList[dateActive][sessionsActive].end
            }}</span>
          </p>
          <template v-for="(item, index) in quantitys">
            <p :key="index" v-if="quantity[item.key]">
              {{ item.name }}：<span>{{ quantity[item.key] }}人</span>
            </p>
          </template>
        </div>
      </div>
      <div class="box">
        <div class="title title_box">
          <span>联系人信息</span>
          <div class="add_btn" @click="addPersonnel(1, 'list', 'list2')">
            添加/更换
          </div>
        </div>
        <div class="form_box" v-if="list.length">
          <div v-for="(item, index) in list" :key="index" class="item">
            <img
              src="@/assets/images/delete_p.png"
              alt=""
              @click="del(item, 'list')"
            />
            <div class="right">
              <div class="info">
                <p class="name">{{ item.name }}</p>
                <p>证件号码：{{ item.documentNumber }}</p>
                <p>手机号码：{{ item.phone }}</p>
              </div>
            </div>
          </div>
        </div>
        <p v-else class="please_select">请选择1位预约人</p>
      </div>
      <div class="box" v-if="quantity.yy > 1">
        <div class="title title_box">
          <span>随行人员信息</span>
          <div
            class="add_btn"
            @click="addPersonnel(quantity.yy - 1, 'list2', 'list')"
          >
            添加/更换
          </div>
        </div>
        <div class="form_box" v-if="list2.length">
          <div v-for="(item, index) in list2" :key="index" class="item">
            <img
              src="@/assets/images/delete_p.png"
              alt=""
              @click="del(item, 'list2')"
            />
            <div class="right">
              <div class="info">
                <p class="name">{{ item.name }}</p>
                <p>证件号码：{{ item.documentNumber }}</p>
                <p>手机号码：{{ item.phone }}</p>
              </div>
            </div>
          </div>
        </div>
        <p v-if="list2.length < quantity.yy - 1" class="please_select">
          <span v-if="list2.length">还需再</span><span v-else>请</span>选择{{
            quantity.yy - 1 - list2.length
          }}位随行人
        </p>
      </div>
    </template>
    <van-button type="primary" class="btn_sty" @click="nextStep">
      {{ active === 0 ? '下一步 填写信息' : '立即预约' }}
    </van-button>
    <van-dialog
      v-model:show="show"
      title="温馨提示"
      show-cancel-button
      confirmButtonText="确认预约"
      confirmButtonColor="#458aef"
      :confirmButtonDisabled="!reading && type === '儿童科学乐园门票预约'"
      @confirm="confirm"
      @close="close"
      class="confirm_tips"
    >
      <div class="content" v-if="type === '科技馆门票预约'">
        <p>
          1、每个微信号每天限约<span style="color: #fd4d4f">{{
            data.maxAllowAppointmentPreDay
          }}</span
          >次，用户主动退票<span style="color: #fd4d4f">不返还预约次数</span
          >，敬请谅解!
        </p>
        <p>
          2、为保障公众权益，杜绝有偿代约行为，请观众凭<span
            style="color: #fd4d4f"
            >已预约的动态二维码或身份证</span
          >进馆参观，截图二维码无效。
        </p>
      </div>
      <div
        class="content"
        v-html="data.tips"
        v-if="type === '儿童科学乐园门票预约'"
      ></div>
      <van-checkbox
        v-model="reading"
        shape="square"
        icon-size="16px"
        v-if="type === '儿童科学乐园门票预约'"
        >我已阅读并遵守以上规定</van-checkbox
      >
    </van-dialog>
    <van-dialog
      v-model:show="show2"
      class="appointment"
      confirm-button-text="知道了"
      confirm-button-color="#fff"
      @confirm="$router.replace('/')"
    >
      <template #title>
        <div class="dialog_tit">
          <van-icon name="checked" /><span>预约成功！</span>
        </div>
      </template>
      <div class="tips">
        <p>
          1、如您不能及时前往，请在您预约的<span class="red_color"
            >{{ type === '儿童科学乐园门票预约' ? '进园' : '进馆' }}时间开始前{{
              data.retreatTimeHour ? data.retreatTimeHour + '小时' : ''
            }}{{ data.retreatTimeMin }}分钟</span
          >提前取消预约（如<span class="red_color"
            >{{ data.maxDefaultsNum }}次</span
          >不及时取消，账号将在<span class="red_color"
            >{{ data.resetDefaultsDay }}天</span
          >内无法进行预约）。
        </p>
        <p>
          2、在“我的—{{
            type === '儿童科学乐园门票预约'
              ? '儿童科学乐园门票预约记录'
              : '门票预约记录'
          }}”中查看订单信息。
        </p>
      </div>
    </van-dialog>
    <MyNotice ref="myNotice" />
    <MyTips ref="myTips" :content="msg" toPath="/" buttonText="去预约" />
    <van-popup
      class="commonly_popup"
      v-model:show="commonlyShow"
      position="right"
    >
      <CommonlyUsed
        :max="max"
        :ids="ids"
        :disableds="disableds"
        @personnel="personnel"
      />
    </van-popup>
  </div>
</template>
<script>
import MyNotice from '@/components/MyNotice.vue';
import MyTips from '@/components/MyTips.vue';
import CommonlyUsed from './CommonlyUsed.vue';
import {
  getCommonConfig,
  ticketScheduleList,
  ticketPersonal,
  playgroundApt,
  playgroundList,
  hasTicketOrder,
} from '@/api/Reservation';
import { contactList } from '@/api/CommonlyUsed';
export default {
  name: 'Reservation',
  data() {
    return {
      type: '',
      active: 0,
      dateList: [],
      scheduleList: [],
      dateActive: null,
      sessionsActive: null,
      steppers: {},
      quantitys: [],
      quantity: {},
      show: false,
      show2: false,
      appointmentApi: null,
      bookApi: null,
      isTips: false,
      msg: '',
      list: [],
      list2: [],
      contactList: [],
      max: 0,
      ids: [],
      disableds: [],
      commonlyShow: false,
      commonlyActive: '',
      data: {},
      notice: '',
      reading: false,
    };
  },
  props: {
    clickRightNum: {
      type: Number,
      default: 0,
    },
    clickLeftNum: {
      type: Number,
      default: 0,
    },
  },
  components: { MyNotice, MyTips, CommonlyUsed },
  created() {
    this.type = this.$route.query.type;
    let notice = sessionStorage.getItem(this.type);
    if (!(notice && notice === '1')) {
      this.$router.push('/');
    }
    if (this.type === '科技馆门票预约') {
      this.getCommonConfig(1);
      this.appointmentApi = ticketScheduleList;
      this.bookApi = ticketPersonal;
    } else if (this.type === '儿童科学乐园门票预约') {
      this.notice = '提示: 预约儿童科学乐园门票前，请确保已预约科技馆参观门票';
      this.getCommonConfig(3);
      this.appointmentApi = playgroundList;
      this.bookApi = playgroundApt;
    }
    this.getAppointment();
  },
  mounted() {},
  methods: {
    del(item, active) {
      this[active] = this[active].filter((item2) => item2.id !== item.id);
    },
    async personnel(ids) {
      let { data } = await contactList();
      this.contactList = data;
      let arr = [];
      ids.map((item) => {
        arr = arr.concat(this.contactList.filter((item2) => item2.id === item));
      });
      this[this.commonlyActive] = arr;
      this.commonlyShow = false;
    },
    addPersonnel(num, active, active2) {
      let ids = [];
      let ids2 = [];
      this.commonlyActive = active;
      this[active].map((item) => {
        ids.push(item.id);
      });
      this[active2].map((item) => {
        ids2.push(item.id);
      });
      this.disableds = ids2;
      this.ids = ids;
      this.max = num;
      this.commonlyShow = true;
    },
    //下一步
    async nextStep() {
      if (this.active === 0) {
        if (this.dateActive === null) {
          this.$notify({
            type: 'danger',
            message: '请先选择日期',
          });
        } else if (this.sessionsActive === null) {
          this.$notify({
            type: 'danger',
            message: '请先选择进馆时间',
          });
        } else if (this.quantity.yy <= 0) {
          this.$notify({
            type: 'danger',
            message: '请添加预约总人数',
          });
        } else if (
          this.quantity.yy <= 1 &&
          this.type === '儿童科学乐园门票预约'
        ) {
          this.$notify({
            type: 'danger',
            message:
              '最少预约2人，联系人必须年满18周岁以上，随行人员必须有1人年龄在14周岁以下!',
          });
        } else {
          this.list = [];
          this.list2 = [];
          this.active++;
        }
      } else if (this.active === 1) {
        let validate = true;
        if (!this.list.length) {
          this.$notify({
            type: 'danger',
            message: '请添加联系人信息',
          });
          validate = false;
        }
        if (this.list2.length < this.quantity.yy - 1 && validate) {
          this.$notify({
            type: 'danger',
            message: '请添加随行人员信息',
          });
          validate = false;
        }
        if (
          this.list.length &&
          this.list2.length &&
          validate &&
          this.type === '儿童科学乐园门票预约'
        ) {
          let list = this.list.concat(this.list2);
          let arr = list.filter((v) => v.documentType !== 1);
          if (arr.length) {
            this.$notify({
              type: 'danger',
              message: '请使用身份证预约儿童科学乐园门票，其他证件暂不支持',
            });
            validate = false;
          }
        }
        if (validate) {
          this.show = true;
        }
      }
    },
    //时间
    async onDate(index) {
      this.dateActive = index;
      this.sessionsActive = null;
      if (this.type === '儿童科学乐园门票预约') {
        this.hasTicketOrder();
      }
    },
    async hasTicketOrder() {
      let { data } = await hasTicketOrder({
        visitDate: this.dateList[this.dateActive].scheduleDate,
      });
      let date = null;
      if (data.length) {
        data.map((v) => {
          let startTime = v.startTime.replace(/\-/g, '/');
          if (date) {
            if (new Date(startTime).getTime() < new Date(date).getTime()) {
              date = startTime;
            }
          } else {
            date = startTime;
          }
        });
        this.scheduleList[this.dateActive].map((v) => {
          let startTime = v.startTime.replace(/\-/g, '/');
          if (new Date(startTime).getTime() >= new Date(date).getTime()) {
            v.cannot = true;
          } else {
            v.cannot = false;
          }
        });
      } else {
        this.dateActive = null;
        this.$toast.fail('请先预约门票');
      }
    },
    //选择场次
    async selectSession(index) {
      this.sessionsActive = index;
      let remainingQuantity =
        this.scheduleList[this.dateActive][this.sessionsActive]
          .remainingQuantity;
      this.steppers.yy = {};
      this.quantity.yy = 0;
      let { maxAllowNumPreOrder } = this.data;
      let max = 0;
      if (remainingQuantity) {
        if (remainingQuantity <= maxAllowNumPreOrder) {
          max = remainingQuantity;
        } else {
          max = maxAllowNumPreOrder;
        }
      }
      this.quantitys = [{ name: '预约总人数', key: 'yy' }];
      if (max) {
        this.steppers.yy = {
          title: '每单限约' + max + '人',
          max: max,
        };
      }
    },
    //确认提交
    async confirm() {
      let scheduleId =
        this.scheduleList[this.dateActive][this.sessionsActive].id;
      let { id } = this.list[0];
      let formData = {
        contactId: id,
        scheduleId,
      };
      let contactsIds = [];
      this.list2.map((v) => {
        contactsIds.push(v.id);
      });
      formData.contactsIds = contactsIds;
      await this.bookApi(formData);
      this.active++;
      this.show2 = true;
    },
    close() {
      this.reading = false;
    },
    //点击步骤的标题或图标
    clickStep(index) {
      if (index < this.active) {
        this.active = index;
      }
    },
    //查询配置
    async getCommonConfig(value) {
      let { data } = await getCommonConfig({ ticketType: value });
      let { refundTimeLimit } = data;
      data.retreatTimeHour = 0;
      data.retreatTimeMin = 0;
      if (refundTimeLimit >= 0) {
        data.retreatTimeHour = parseInt(refundTimeLimit / 60);
        data.retreatTimeMin = refundTimeLimit - data.retreatTimeHour * 60;
      }
      this.data = data;
    },
    //预约详情信息
    async getAppointment() {
      let {
        data: { scheduleByDayMap, scheduleList, currentHour },
      } = await this.appointmentApi({});
      // let date = new Date();
      // let time =
      //   date.getFullYear() +
      //   "-" +
      //   (date.getMonth() + 1 >= 10 ? "" : "0") +
      //   (date.getMonth() + 1) +
      //   "-" +
      //   (date.getDate() >= 10 ? "" : "0") +
      //   date.getDate();

      const days = ['日', '一', '二', '三', '四', '五', '六'];
      await scheduleByDayMap.forEach((item, index) => {
        let weekDay = new Date(item.scheduleDate).getDay();
        // item.title = time === item.scheduleDate ? "今天" : days[weekDay];
        item.title = days[weekDay];
        let useDates = item.scheduleDate.split('-');
        item.date = useDates[1] + '/' + useDates[2];
        item.day = useDates[1] + '.' + useDates[2];
        let arr = scheduleList.filter(
          (item2) => item.scheduleDate === item2.scheduleDate.split(' ')[0]
        );
        // if (item.scheduleDate === "2024-01-01") {
        //   item.isClose = false;
        //   item.isDue = false;
        // }
        if (arr.length) {
          arr.map((v) => {
            let { endTime, startTime, scheduleDate, remainingQuantity } = v;
            if (
              this.type === '儿童科学乐园门票预约' &&
              remainingQuantity === 1
            ) {
              v.remainingQuantity = 0;
              item.remainingQuantity = item.remainingQuantity - 1;
            }
            v.end = endTime.split(' ')[1];
            v.start = startTime.split(' ')[1];
            v.cannot = true;
            // if (scheduleDate === "2024-01-01 00:00:00") {
            //   v.status = 1;
            // }
          });
        }
        this.scheduleList.push(arr);
        if (scheduleByDayMap.length === index + 1) {
          if (currentHour < 10) {
            item.server = false;
          } else {
            item.server = true;
          }
        } else {
          item.server = true;
        }
        if (!item.isClose && item.server && item.remainingQuantity > 0) {
          item.isClick = true;
        } else {
          item.isClick = false;
        }
      });
      this.dateList = scheduleByDayMap;
    },
  },
  watch: {
    //点击预约须知
    clickRightNum() {
      let type = '';
      switch (this.type) {
        case '科技馆门票预约':
          type = '科技馆门票预约';
          break;
        case '儿童科学乐园门票预约':
          type = '儿童科学乐园门票预约';
          break;
        default:
          break;
      }
      this.$refs.myNotice.init(type, false, this.data.notice);
    },
    //点击返回
    clickLeftNum() {
      if (this.active) {
        this.active--;
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.reservation {
  height: 100%;
  background: #f5f6fa;
  min-height: calc(100vh - 92px);
  padding-bottom: 46px;
  ::v-deep .van-sticky {
    background: #fff;
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    .van-steps {
      width: 100%;
      padding: 0 50px;
      .van-steps__items {
        .van-hairline {
          .van-step__circle-container,
          .van-step__line {
            top: 38px;
          }
          .van-step__title {
            font-size: 16px;
          }
          .van-step__circle-container {
            .van-icon {
              font-size: 16px;
            }
            .van-step__circle {
              width: 10px;
              height: 10px;
            }
          }
        }
        .van-step--horizontal:first-child {
          .van-step__title {
            margin-left: -20px;
          }
        }
        .van-step--horizontal:last-child {
          .van-step__title {
            margin-right: -20px;
          }
        }
      }
    }
  }
  .box {
    margin: 10px;
    background: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    .title {
      padding-bottom: 10px;
      border-bottom: 1px solid #ececec;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: rgb(51, 51, 51);
      }
      .add_btn {
        font-size: 14px;
        font-weight: 400;
        color: #0b6cf9;
      }
    }
    .please_select {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      margin-top: 10px;
    }
    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .profile {
      margin-top: 10px;
      p {
        font-size: 14px;
        color: #666;
        line-height: 24px;
      }
    }
    .date_box {
      // padding: 20px 20px 10px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px 10px;
      padding: 0;
      margin: 15px 0;
      .date_item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 60px;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        span {
          font-size: 12px;
          line-height: 20px;
        }
        img {
          position: absolute;
          width: 25px;
          height: 25px;
          right: 0;
          top: 0;
        }
      }
    }
    .sessions {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      > div {
        position: relative;
        width: 47%;
        height: 70px;
        font-size: 14px;
        color: #076cfa;
        text-align: center;
        border: 1px solid #076cfa;
        border-radius: 5px;
        margin: 6px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > img {
          position: absolute;
          width: 25px;
          height: 25px;
          right: 0;
          top: 0;
        }
      }
      .state {
        color: #999;
        border: 1px solid #999;
      }
      .sessionsActive {
        background: #076cfa;
        color: #fff;
      }
      .tips {
        font-size: 16px;
      }
    }
    .quantity {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        > p {
          color: #333;
          font-size: 14px;
        }
        > span {
          color: #999;
          font-size: 12px;
        }
        ::v-deep .van-stepper {
          height: 24px;
          line-height: 24px;
          .van-stepper__minus {
            color: #0b6cf9;
            border: 1px solid #0b6cf9;
          }
          .van-stepper__plus {
            color: #0b6cf9;
            background: #fff;
            border: 1px solid #0b6cf9;
          }
        }
      }
    }
    .quantity + .quantity {
      padding-top: 15px;
      border-top: 1px solid #ececec;
    }
    ::v-deep .form_box {
      background: #fdfdfd;
      border-radius: 5px;
      .item {
        border-bottom: 1px solid #dbdbdb;
        background-color: #fff;
        padding: 10px 20px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          display: block;
        }
        .right {
          width: 100%;
          padding: 0 10px;
          margin-left: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .info {
            p {
              font-size: 13px;
              line-height: 20px;
            }
            .name {
              font-size: 15px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  .information {
    margin: 10px;
    background-color: #fefbf1;
    border: 1px solid #ffb500;
    border-radius: 5px;
    padding: 10px 15px;
    .tips {
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      color: #f9871e;
      line-height: 20px;
    }
  }
  .red_color {
    color: #fd4d4f;
  }
  .btn_sty {
    position: fixed;
    bottom: 0;
    width: 100%;
    line-height: 44px;
    height: 44px;
    font-weight: 650;
    font-size: 16px;
    color: #fff;
  }
  ::v-deep .confirm_tips {
    .van-dialog__header {
      padding: 0;
      line-height: 50px;
      font-weight: bold;
    }
    .van-dialog__content {
      padding: 0 15px 10px;
      font-size: 16px;
      font-weight: 400;
      .content {
        line-height: 1.8;
        overflow-y: auto;
        max-height: 66vh;
      }
      .van-checkbox {
        margin-top: 10px;
        .van-checkbox__label {
          color: #1989fa;
        }
      }
    }
  }
  ::v-deep .appointment {
    .van-dialog__header {
      padding: 15px 0;
      font-size: 16px;
      color: #333333;
      font-weight: 650;
      .dialog_tit {
        display: flex;
        justify-content: center;
        align-items: center;
        > i {
          color: #4bd863;
          font-size: 22px;
          margin-right: 10px;
        }
      }
    }
    .van-dialog__content {
      padding: 0 20px 20px;
      .tips {
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #333;
        line-height: 24px;
      }
    }
    .van-dialog__footer {
      .van-button {
        background-color: #3069f0;
      }
    }
  }
  ::v-deep .commonly_popup {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    .commonlyUsed {
      height: 100vh;
      .van-checkbox-group {
        margin-bottom: 60px;
      }
    }
  }
}
</style>
